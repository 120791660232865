import { Modal, Button } from 'react-bootstrap';

const DialogWrapper = ({ title, content, show, buttons, dialogContent: DialogContent, dialogSize }) => {

  const dialogSizeWidth = dialogSize || 50;

  const createMarkup = (htmlString) => {
    return { __html: htmlString };
  };

  const modalStyle = `
  .modal-size {
    max-width: none !important;
    width: ${dialogSizeWidth}%;
  }

  .modal-body {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
  }
  `;

  return (
    <>
      <style>{modalStyle}</style>
      <Modal show={show} dialogClassName="modal-size">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={createMarkup(content)} />
          {DialogContent ? <DialogContent /> : null}
        </Modal.Body>
        <Modal.Footer>
          {buttons.map((button, index) => (
            <Button key={index} variant={button.variant} onClick={button.onClick}>
              {button.text}
            </Button>
          ))}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DialogWrapper;
