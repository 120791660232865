import React, { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import UserContext from '../context/UserContext';
import appConfiguration from '../cfg/AppCfg';
import { useNavigate } from 'react-router-dom';

const MobileMenu = () => {

  const { user} = useContext(UserContext);
  document.title = appConfiguration.documentTitle;

  useEffect(() => {
    if (user) document.documentElement.style.setProperty('--kolor_aplikacji', user.layout);
  }, [user]);

  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-center mt-4">
      <Button onClick={() => navigate('klienci/lista')} style={{ marginRight: 1 + 'em' }}>Klienci</Button>
      <Button onClick={() => navigate('produkty/lista')} style={{ marginRight: 1 + 'em' }}>Produkty</Button>
      <Button onClick={() => navigate('Camera')} style={{ marginRight: 1 + 'em' }}>Kamera</Button>
      <Button onClick={() => navigate('CurrentPosition')} style={{ marginRight: 1 + 'em' }}>Pozycje GPS</Button>
      <Button onClick={() => navigate('ankietySzablony')} style={{ marginRight: 1 + 'em' }}>Ankiety</Button>
    </div>
  );
};

export default MobileMenu;
