import React, { useState } from 'react';
import { defaultSpaceBetweenQuestion, defaultSpaceLeftDependQuestion } from '../QuestionnaireCfg';
import { Form } from 'react-bootstrap';

const QuestionnaireRenderQuestionInputPictures = ({ index, question, formik, counter, prefix }) => {

  const fieldName = question.idField;
  const [images, setImages] = useState([]);

  if (!question.show) return null;

  const clearPicture = (indexToRemove) => {
    const newImages = [...images];
    newImages[indexToRemove] = null;
    setImages(newImages);
    clearFileInput(`${fieldName}_${indexToRemove}`);
    formik.setFieldValue(`${fieldName}_${indexToRemove}`, '');
  };

  const clearFileInput = (fieldToClear) => {
    const input = document.querySelector(`input[name="${fieldToClear}"]`);
    if (input) {
      input.value = null;
    }
  };

  const renderFormList = () => {
    const items = [];
    for (let index = 0; index < question.cfg.maxQuantity; index++) {
      const imgId = `${fieldName}_pic_${index}`;
      items.push(
        <div key={index} className="input-group">
          <Form.Control
            type='file'
            name={`${fieldName}_${index}`}
            className="form-control mt-3"
            onChange={handleImageChange(index)}
            onBlur={formik.handleBlur}
          //isInvalid={formik.touched[fieldName] && formik.errors[fieldName]}
          />
          <button
            type="button"
            onClick={() => clearPicture(index)}
            className="btn btn-outline-secondary align-self-center"
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      );
    }
    return items;
  }

  const renderPicutres = () => {
    const items = [];
    for (let index = 0; index < question.cfg.maxQuantity; index++) {
      if (images[index]) {
        items.push(
          <div style={{ display: 'inline-block' }} className='mx-3'>
            <img id={`${fieldName}_${index}_pic`} src={images[index]} style={{ maxWidth: '320px' }} alt="Uploaded" />
          </div>
        )
      }
    }
    return items;
  }

  const handleImageChange = (index) => (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    const newImages = [...images];
    newImages[index] = imageUrl;
    setImages(newImages);
  }

  return (
    <div
      className={counter % 2 === 0 ? 'secondaryQuestion' : ''}
      style={{ paddingLeft: defaultSpaceLeftDependQuestion, paddingTop: defaultSpaceBetweenQuestion, paddingBottom: defaultSpaceBetweenQuestion }}
    >
      <div style={{
        paddingLeft: question.ifReqField && question.ifReqField.length > 0 ? defaultSpaceLeftDependQuestion : '0',
      }}>
        <label
          htmlFor={fieldName + 'i'}
          className={`mt-2 questionHead ${question.ifReqField ? 'questionDepended' : ''}`}
        >
          {!question.ifReqField && <span>{counter}. </span>}
          {question.question}
        </label>
        {renderFormList()}
        <hr />
        {renderPicutres()}
        <hr />
      </div>
      {
        JSON.stringify(images)
      }
    </div>
  );

};

export default QuestionnaireRenderQuestionInputPictures;