import { useTranslation } from 'react-i18next';

const QuestionnaireTypeQuestion = ({ index, handleChange, value }) => {

  const { t } = useTranslation();

  return (
    <select
      id={index}
      onChange={(event) => handleChange(index, event)}
      className="form-select w-100"
      value={value}
    >
      <option value="input">{t("otwarte krótka treść")}</option>
      <option value="textarea">{t("otwarte długa treść")}</option>
      <option value="radio">{t("jednokrotnego wyboru")}</option>
      <option value="checkbox">{t("wielokrotnego wyboru")}</option>
      <option value="infoblok">{t("blok informacyjny")}</option>
      <option value="inputFile">{t("plik")}</option>
      <option value="select">{t("lista wyboru")}</option>
      <option value="crossprod">{t("produkty i szablony")}</option>
      <option value="blockTemplate">{t("blok szablonu")}</option>
      <option value="datehour">{t("data i godziny")}</option>
      <option value="pictures">{t("zdjęcia")}</option>
    </select>
  );
};

export default QuestionnaireTypeQuestion;