import React, { useContext, useState } from 'react';
import UserContext from '../context/UserContext';
import appConfiguration from '../cfg/AppCfg';
import { Accordion } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AccordionMain = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const accordionOpen = localStorage.getItem('AccordionOpen') ? localStorage.getItem('AccordionOpen') : '0';
  const [activeKey, setActiveKey] = useState('' + accordionOpen);

  const handleSelect = (eventKey) => {
      setActiveKey(activeKey === eventKey ? '' : eventKey);
      localStorage.setItem('AccordionOpen', eventKey);
  };
  document.title = appConfiguration.documentTitle;

  return (
    <div style={{ width: '210px', flexShrink: 0, margin: 0 }}>
      <Accordion onSelect={handleSelect} activeKey={activeKey}>

        {user.modules.includes('ankiety')
          && (
            <Accordion.Item eventKey="0">
              <Accordion.Header>{t('menuAnkiety')}</Accordion.Header>
              <Accordion.Body>
                <Link to="ankiety/0">
                  Nowa ankieta
                </Link>
                <br />
                <Link to="ankietySzablony/">
                  Lista szablonów
                </Link>
                <br />
                <Link to="ankietyAnkiety/">
                  Lista ankiet
                </Link>
              </Accordion.Body>
            </Accordion.Item>
          )}
        {user.modules.includes('user')
          && (
            <Accordion.Item eventKey="1">
              <Accordion.Header>Użytkownik</Accordion.Header>
              <Accordion.Body>
                <Link to="info">
                  Informacje o użytkowniku
                </Link>
                <br />
                <Link to="passwordchange">
                  Zmień hasło
                </Link>
                <br />

              </Accordion.Body>
            </Accordion.Item>
          )}
        {user.modules.includes('admin')
          && (
            <Accordion.Item eventKey="2">
              <Accordion.Header>Administracja</Accordion.Header>
              <Accordion.Body>
                <Link to="/administracja">
                  Administacja
                </Link>
                <br />
                <Link to="userChange">
                  Podmina użytkownika
                </Link>
              </Accordion.Body>
            </Accordion.Item>
          )}
        {user.modules.includes('dev')
          && (
            <>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Developer Workspace</Accordion.Header>
                <Accordion.Body>
                  <Link to="datatable/produkty/color/234">
                    Produkty
                  </Link>
                  <br />
                  <Link to="hook_userContext">
                    UserContext
                  </Link>
                  <br />
                  <Link to="Camera">
                    Camera
                  </Link>
                  <br />
                  <Link to="katalogankiet">
                    DataTable1
                  </Link>
                  <br />
                  <Link to="testdt">
                    DataTable2
                  </Link>
                </Accordion.Body>
              </Accordion.Item>
            </>
          )}
        <Accordion.Item eventKey="7">
          <Accordion.Header className="no-arrow" onClick={() => navigate('CurrentPosition')}>Pozycja GPS</Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header className="no-arrow" onClick={() => navigate('klienci/lista')}>Klienci</Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
          <Accordion.Header className="no-arrow" onClick={() => navigate('produkty/lista')}>Produkty</Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="10">
          <Accordion.Header className="no-arrow" onClick={() => navigate('produkty/listaGrup/')}>Listy Produktów </Accordion.Header>
        </Accordion.Item>

      </Accordion>
    </div>
  );
};

export default AccordionMain;