import React, { useState, useEffect, useContext } from 'react';
import { QuestionnaireRenderQuestionOnly, generateFormikConfiguration } from '../QuestionnaireRender';
import DictionaryContext from '../../context/DictionaryContext';

const QuestionnaireRenderQuestionBlockTemplate = ({ index, question, formik, counter, addSchemaCfg }) => {

    const [questions, setQuestions] = useState([]);
    const [questionCfg, setQuestionsCfg] = useState([]);

    useEffect(() => {

        fetch(`/json/ankieta_${question.cfg.idSchemaTemplate}.json`)
            .then(response => response.json())
            .then(data => {
                setQuestions(data.questionsCfg);
            })
            .catch(error => console.error('Błąd podczas odbierania danych:', error));

    }, []);

    useEffect(() => {
        if (questions.length > 0) {
            const tmpConfig = [];
            for (let j = 0; j < questions.length; j++) {
                const newQuestion = { ...questions[j] };
                newQuestion.question = newQuestion.question;
                tmpConfig.push(newQuestion)
            }
            setQuestionsCfg(tmpConfig);
            const { initialValues, validationSchema } = generateFormikConfiguration({ questions: tmpConfig, prefix: '_subform_' + counter });
            addSchemaCfg(initialValues, validationSchema);

        }
    }, [questions]);

    if (!question.show) return null;

    return (
        <ul>
            <hr />
            <h3>{question.question}</h3>
            <ol>
                <QuestionnaireRenderQuestionOnly
                    questions={questionCfg}
                    formik={formik}
                    counter={counter * 10000}
                    prefix={'_subform_' + counter}
                />
            </ol>
            <hr />
        </ul>
    );
};

export default QuestionnaireRenderQuestionBlockTemplate;
