import React from 'react';
import QuestionnaireTypeQuestionInput from './questionnaireTypeQuestion/QuestionnaireTypeQuestionInput';
import QuestionnaireTypeQuestionInputFile from './questionnaireTypeQuestion/QuestionnaireTypeQuestionInputFile';
import QuestionnaireTypeQuestionTextarea from './questionnaireTypeQuestion/QuestionnaireTypeQuestionTextarea';
import QuestionnaireTypeQuestionRadio from './questionnaireTypeQuestion/QuestionnaireTypeQuestionRadio';
import QuestionnaireTypeQuestionCheckbox from './questionnaireTypeQuestion/QuestionnaireTypeQuestionCheckbox';
import QuestionnaireTypeQuestionSelect from './questionnaireTypeQuestion/QuestionnaireTypeQuestionSelect';
import QuestionnaireTypeQuestionCrossProducts from './questionnaireTypeQuestion/QuestionnaireTypeQuestionCrossProducts';
import QuestionnaireTypeQuestionBlockTemplate from './questionnaireTypeQuestion/QuestionnaireTypeQuestionBlockTemplate';
import QuestionnaireTypeQuestionInputDateHour from './questionnaireTypeQuestion/QuestionnaireTypeQuestionInputDateHour';
import QuestionnaireTypeQuestionInputPictures from './questionnaireTypeQuestion/QuestionnaireTypeQuestionInputPictures';
import { useTranslation } from 'react-i18next';
import FormModule from '../cfg/ConstantStrings';

const QuestionnaireTypeQuestionConfiguration = ({ index, questions, setQuestions }) => {

  const { t } = useTranslation();

  const renderQuestionComponent = () => {
    switch (questions[index].type) {
      case FormModule.INPUT:
        return <QuestionnaireTypeQuestionInput
          index={index}
          questions={questions}
          setQuestions={setQuestions}
        />;
      case FormModule.INPUT_FILE:
        return <QuestionnaireTypeQuestionInputFile
          index={index}
          questions={questions}
          setQuestions={setQuestions}
        />;
      case FormModule.TEXTAREA:
        return <QuestionnaireTypeQuestionTextarea
          index={index}
          questions={questions}
          setQuestions={setQuestions}
        />;
      case FormModule.RADIO:
        return <QuestionnaireTypeQuestionRadio
          index={index}
          questions={questions}
          setQuestions={setQuestions}
        />;
      case FormModule.CHECKBOX:
        return <QuestionnaireTypeQuestionCheckbox
          index={index}
          questions={questions}
          setQuestions={setQuestions}
        />;
      case FormModule.SELECT:
        return <QuestionnaireTypeQuestionSelect
          index={index}
          questions={questions}
          setQuestions={setQuestions}
        />;
      case FormModule.CROSSPROD:
        return <QuestionnaireTypeQuestionCrossProducts
          index={index}
          questions={questions}
          setQuestions={setQuestions}
        />;
      case FormModule.BLOCKTEMPLATE:
        return <QuestionnaireTypeQuestionBlockTemplate
          index={index}
          questions={questions}
          setQuestions={setQuestions}
        />;
      case FormModule.INFOBLOCK:
        return t('To jest blok informacyny. Jego treść proszę wpisać w pytanie.')
      case FormModule.DATEHOUR:
        return <QuestionnaireTypeQuestionInputDateHour
          index={index}
          questions={questions}
          setQuestions={setQuestions}
        />;
      case FormModule.PICTURES:
        return <QuestionnaireTypeQuestionInputPictures
          index={index}
          questions={questions}
          setQuestions={setQuestions}
        />;
      default:
        return 'EMPTY -> ' + questions[index].type;
    }
  };

  return (
    <div>
      {renderQuestionComponent()}
    </div>
  );
};

export default QuestionnaireTypeQuestionConfiguration;