import React from "react";
import { defaultSpaceBetweenQuestion, defaultSpaceLeftDependQuestion } from '../QuestionnaireCfg';
import { Form } from 'react-bootstrap';

const QuestionnaireRenderQuestionInput = ({ index, question, formik, counter, prefix }) => {

  const fieldName = question.idField;

  if (!question.show) return null;

  return (
    <div
      className={counter % 2 === 0 ? 'secondaryQuestion' : ''}
      style={{ paddingLeft: defaultSpaceLeftDependQuestion, paddingTop: defaultSpaceBetweenQuestion, paddingBottom: defaultSpaceBetweenQuestion }}
    >
      <div style={{
        paddingLeft: question.ifReqField && question.ifReqField.length > 0 ? defaultSpaceLeftDependQuestion : '0',
      }}>
        <label
          htmlFor={fieldName + 'i'}
          className={`mt-2 questionHead ${question.ifReqField ? 'questionDepended' : ''}`}
        >
          {!question.ifReqField && <span>{counter}. </span>}
          {question.question}
        </label>
        <div className='questionOption mt-1'>
          <Form.Control
            id={fieldName + 'i'}
            name={fieldName}
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[fieldName]}
            isInvalid={formik.touched[fieldName] && formik.errors[fieldName]}
          />
          {formik.touched[fieldName] && formik.errors[fieldName] && (
            <Form.Control.Feedback type="invalid">
              {formik.errors[fieldName]}
            </Form.Control.Feedback>
          )}
        </div>
      </div>
    </div>
  );

};

export default QuestionnaireRenderQuestionInput;