import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import DialogWrapper from '../DialogWrapper';
import QuestionnaireTypeQuestion from './QuestionnaireTypeQuestion';
import QuestionnaireTypeQuestionConfiguration from './QuestionnaireTypeQuestionConfiguration';
import QuestionnaireRender from './QuestionnaireRender'
import { useParams } from 'react-router-dom';
import { describeQuestionConfigKeyValue } from '../common/CommonFunction';
import { useTranslation } from 'react-i18next';
import DictionaryContext from '../context/DictionaryContext';
import FormModule from '../cfg/ConstantStrings';

const DynamicForm = () => {

  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const { describeDictionaryValue } = useContext(DictionaryContext);

  const defaultQuestionCfg = { question: '', type: FormModule.INPUT, cfg: { validation: 'empty', minLength: 0, maxLength: 0 }, show: true, idField: 1 };
  const defaultFormCfg = { questionnaireName: '', questionnaireDescription: '', idQuestionnaire: 0 };
  const [formCfg, setFormCfg] = useState(defaultFormCfg);
  const [questions, setQuestions] = useState([defaultQuestionCfg]);
  const [questionsTransform, setQuestionsTransform] = useState([]);

  const [showDialog, setShowDialog] = useState(false);
  const [dialogProps, setDialogProps] = useState({ title: '', content: '', handleTak: '', buttons: [], dialogContent: null, dialogSize: 50 });

  const handleQuestionConfiguration = (index) => {
    setDialogProps({
      title: t('Pytanie') + ' : ' + questions[index].question,
      buttons: [
        { text: t('zamknij'), variant: 'primary', onClick: handleClose }
      ],
      dialogContent: () => <QuestionnaireTypeQuestionConfiguration
        index={index}
        questions={questions}
        setQuestions={setQuestions}
      />
    });
    setShowDialog(true);
  }
  const handleClose = () => setShowDialog(false);

  const handleCopyToClipboard = (str) => {
    navigator.clipboard.writeText(str)
    setShowDialog(false);
  }

  const processQuestions = async (questions) => {
    const newQuestions = [];
    let idTransform = {};

    for (const question of questions) {
      const updatedQuestion = { ...question };

      if (question.type === FormModule.CROSSPROD && question.cfg.idSchemaTemplate > 0 && question.cfg.idProductList > 0) {
        try {
          const responseQuestionCfg = await fetch(`/json/ankieta_${question.cfg.idSchemaTemplate}.json`);
          const dataQuestionCfg = await responseQuestionCfg.json();

          const responseProductCfg = await fetch(`/json/listaProduktow_${question.cfg.idProductList}.json`);
          const dataProductCfg = await responseProductCfg.json();

          for (let i = 0; i < dataProductCfg.idProduct.length; i++) {
            for (let j = 0; j < dataQuestionCfg.questionsCfg.length; j++) {
              const dataQuestion = { ...dataQuestionCfg.questionsCfg[j] };
              dataQuestion.question = dataQuestion.question.replace('%PRODUKT%', describeDictionaryValue('Produkt', dataProductCfg.idProduct[i]));
              let maxIdField = findMaxIdFieldValue(newQuestions);
              maxIdField++;
              let newIdField = maxIdField;
              idTransform[dataQuestion.idField] = newIdField;
              dataQuestion.prodId = dataProductCfg.idProduct[i];
              dataQuestion.idField = newIdField;
              if (dataQuestion.ifReqField !== undefined && idTransform[dataQuestion.ifReqField] !== undefined) {
                dataQuestion.ifReqField = idTransform[dataQuestion.ifReqField];
              }
              newQuestions.push(dataQuestion);
            }
          }
        } catch (error) {
          console.error('Błąd podczas odbierania danych:', error);
        }

      } else if (question.type === FormModule.BLOCKTEMPLATE) {
        try {
          const response = await fetch(`/json/ankieta_${question.cfg.idSchemaTemplate}.json`);
          const data = await response.json();

          for (let j = 0; j < data.questionsCfg.length; j++) {
            const newQuestion = { ...data.questionsCfg[j] };
            let maxIdField = findMaxIdFieldValue(newQuestions);
            maxIdField++;
            let newIdField = maxIdField;
            idTransform[newQuestion.idField] = newIdField;
            newQuestion.idField = newIdField;
            if (newQuestion.ifReqField !== undefined && idTransform[newQuestion.ifReqField] !== undefined) {
              newQuestion.ifReqField = idTransform[newQuestion.ifReqField];
            }
            newQuestions.push(newQuestion);
          }
        } catch (error) {
          console.error('Błąd podczas odbierania danych:', error);
        }
      } else {
        newQuestions.push(updatedQuestion);
      }
    }

    return newQuestions;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let transformedQuestions = questions;

        while (transformedQuestions.some(question => ((question.type === FormModule.CROSSPROD && question.cfg.idSchemaTemplate > 0 && question.cfg.idProductList > 0) || question.type === FormModule.BLOCKTEMPLATE && question.cfg.idSchemaTemplate > 0))) {
          transformedQuestions = await processQuestions(transformedQuestions);
        }
        setQuestionsTransform(transformedQuestions);
      } catch (error) {
        console.error('Błąd podczas przetwarzania danych:', error);
      }
    };

    fetchData();
  }, [questions]);

  const handlePreview = () => {

    const updateQuestions = [...questions];
    setQuestions(updateQuestions);

    setDialogProps({
      title: '',
      content: '',
      dialogSize: '98',
      buttons: [
        { text: t('zamknij'), variant: 'secondary', onClick: handleClose }
      ],
      dialogContent: () => (
        <QuestionnaireRender
          formCfg={formCfg}
          questions={questionsTransform}
          setQuestions={setQuestionsTransform}
        />
      )
    });
    setShowDialog(true);

  }

  const TransformQuestions = () => {
    setQuestions(questionsTransform);
    handleClose();
  }

  const handleTransformQuestions = () => {
    setDialogProps({
      title: t("Czy chcesz kontynuować?"),
      dialogSize: '80',
      content: 'Ten proces przekształca obecny wygląd szablonu ankiety ("bloki szablonów" i "produkty i szablony") docelowy (podstawowy). Dzięki niemu można modyfikować pytania utworzone automatycznie do docelowego wyglądu.<br> Czy na pewo chcesz skonwertować anketę do wyglądu docelowego? ',
      buttons: [
        { text: t('Zamknij'), variant: 'secondary', onClick: handleClose },
        { text: t('Tak'), variant: 'primary', onClick: TransformQuestions },
      ],
    });
  }

  const handleSaveSchema = () => {
    let questionnaireCfg = {};
    questionnaireCfg.formCfg = formCfg;
    questionnaireCfg.questionsCfg = questions;

    let questionnaireCfgTransform = {};
    questionnaireCfgTransform.formCfg = formCfg;
    questionnaireCfgTransform.questionsCfg = questionsTransform;

    setDialogProps({
      title: t("Czy chcesz kontynuować?"),
      dialogSize: '80',
      content: '<pre><b>Orginał</b><br>' + JSON.stringify(questionnaireCfg) + '</pre><pre><b>Docelowa</b><br>' + JSON.stringify(questionnaireCfgTransform) + '</pre>',
      buttons: [
        { text: t('Zamknij'), variant: 'secondary', onClick: handleClose },
        { text: t('Skopiuj oryginał do schowka'), variant: 'secondary', onClick: () => handleCopyToClipboard(JSON.stringify(questionnaireCfg)) },
        { text: t('Skopiuj docelowa do schowka'), variant: 'secondary', onClick: () => handleCopyToClipboard(JSON.stringify(questionnaireCfgTransform)) },
        { text: t('Przekształć oryginał na docelową'), variant: 'secondary', onClick: handleTransformQuestions },
      ],
    });

    setShowDialog(true);
  }

  const handleCleanSchema = () => {
    setDialogProps({
      title: t("Czy chcesz kontynuować?"),
      content: t("Chcesz wyczyścić aktualną konfigurację ankiety?"),
      buttons: [
        { text: t('Tak'), variant: 'primary', onClick: handleFormClear },
        { text: t('Nie'), variant: 'secondary', onClick: handleClose }
      ]
    });
    setShowDialog(true);
  }

  useEffect(() => {
    if (id > 0 || !id) {
      fetch(`/json/ankieta_${id}.json`)
        .then(response => response.json())
        .then(data => {
          const tab = data.formCfg;
          tab.idQuestionnaire = `ankieta-szablon-${id}`;
          setFormCfg(tab);
          setQuestions(data.questionsCfg);
        })
        .catch(error => console.error('Błąd podczas odbierania danych:', error));
    } else {
      setQuestions([defaultQuestionCfg]);
      setFormCfg(defaultFormCfg);
    }
  }, [id]);

  const handleFormClear = () => {
    handleClose();
    setQuestions([defaultQuestionCfg]);
    setFormCfg(defaultFormCfg);
  }

  const handleFormSend = () => {
    handleClose();
    const config = { formCfg: formCfg, questionsCfg: questions }
    alert(JSON.stringify(config));
  };

  const handleSelectQuestionChange = (index, event) => {
    const updateQuestions = [...questions];
    updateQuestions[index].type = event.target.value;
    switch (event.target.value) {
      case FormModule.INPUT:
        updateQuestions[index]['cfg'] = { validation: 'empty', minLength: 0, maxLength: 0 };
        break;
      case FormModule.TEXTAREA:
        updateQuestions[index]['cfg'] = { minLength: 0, maxLength: 0 };
        break;
      case FormModule.RADIO:
        updateQuestions[index]['cfg'] = { visualization: 'horizontal', variantCfg: [{ variant: '' }] };
        break;
      case FormModule.CHECKBOX:
        updateQuestions[index]['cfg'] = { variantCfg: [{ variant: '' }], minChecked: 0, maxChecked: 0, visualization: 'horizontal' };
        break;
      case FormModule.INFOBLOCK:
        updateQuestions[index]['cfg'] = {};
        break;
      case FormModule.INPUT_FILE:
        updateQuestions[index]['cfg'] = { type: 'photo', quantity: 'single' };
        break;
      case FormModule.CROSSPROD:
        updateQuestions[index]['cfg'] = { idSchemaTemplate: 0, idProductList: 0 };
        break;
      case FormModule.SELECT:
        updateQuestions[index]['cfg'] = { minChecked: 1, maxChecked: 1, sizeList: 1, variantCfg: [{ variant: '' }] };
        break;
      case FormModule.BLOCKTEMPLATE:
        updateQuestions[index]['cfg'] = { idSchemaTemplate: 0 };
        break;
      case FormModule.DATEHOUR:
        updateQuestions[index]['cfg'] = { type: 'date' };
        break;
      case FormModule.PICTURES:
        updateQuestions[index]['cfg'] = { minQuantity: 1, maxQuantity: 1 };
        break;
      default:
        break;
    }
    setQuestions(updateQuestions);
  };

  const findMaxIdFieldValue = (questionsCfg) => {
    let maxIdFieldValue = 0;

    questionsCfg.forEach((question) => {
      const idFieldValue = parseInt(question.idField, 10);
      if (idFieldValue > maxIdFieldValue) {
        maxIdFieldValue = idFieldValue;
      }
    });

    return maxIdFieldValue;
  };

  const addQuestion = () => {
    let maxIdField = findMaxIdFieldValue(questions);
    const adddefaultQuestionCfg = defaultQuestionCfg;
    maxIdField++;
    adddefaultQuestionCfg.idField = maxIdField;
    setQuestions([...questions, adddefaultQuestionCfg]);
  };

  const deleteQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const updateQuestion = (index, event) => {
    const updateQuestions = [...questions];
    updateQuestions[index].question = event.target.value;
    setQuestions(updateQuestions);
  };

  const moveUpQuestion = (index) => {
    const newQuestions = [...questions];
    let temp = newQuestions[index - 1];
    newQuestions[index - 1] = newQuestions[index];
    newQuestions[index] = temp;
    setQuestions(newQuestions);
  };

  const moveDownQuestion = (index) => {
    const newQuestions = [...questions];
    let temp = newQuestions[index + 1];
    newQuestions[index + 1] = newQuestions[index];
    newQuestions[index] = temp;
    setQuestions(newQuestions);
  };

  const describeQuestionConfig = (index) => {
    const questionCfg = questions[index].cfg;

    if (!questionCfg) {
      return <div>{t("Brak konfiguracji dla tego pytania")}</div>;
    }

    const configEntries = Object.entries(questionCfg).map(([key, value]) => {
      switch (key) {
        case 'idSchemaTemplate':
          return <li key={key}><strong>{t("szablon ankiety")}:</strong> {describeDictionaryValue('szablonAnkiet', value)}</li>;
        case 'idProductList':
          return <li key={key}><strong>{t("lista produktów")}:</strong> {describeDictionaryValue('szablonProduktow', value)}</li>;
        default:
          return (
            <li key={key}>
              <strong>{describeQuestionConfigKeyValue(key)}:</strong> {describeQuestionConfigKeyValue(value)}
            </li>
          );
      }
    });

    return (
      <ul>
        {configEntries}
      </ul>
    );
  }

  return (
    <>
      <DialogWrapper
        title={dialogProps.title}
        content={dialogProps.content}
        show={showDialog}
        buttons={dialogProps.buttons}
        dialogContent={dialogProps.dialogContent}
        dialogSize={dialogProps.dialogSize}
      />
      <form>
        <hr className="border-2" />
        {questions.map((question, index) => (
          <div key={index}>
            <label htmlFor={`question-${index}`} style={{ fontSize: '80%' }}>{t('moduł')} {index + 1}</label>
            <div className="input-group mb-3">
              <textarea
                id={`question-${index}`}
                type="text"
                value={question.question || ''}
                onChange={(event) => updateQuestion(index, event)}
                className="form-control surveyEditor"
              />
              <div className="input-group-append">
                <button
                  type="button"
                  onClick={() => deleteQuestion(index)}
                  className="btn btn-outline-secondary"
                >
                  <i className="bi bi-trash"></i>
                </button>
                <br />
                <button
                  type="button"
                  onClick={() => moveUpQuestion(index)}
                  disabled={index === 0}
                  className="btn btn-outline-secondary"
                >
                  <i className="bi bi-arrow-up"></i>
                </button>
                <br />
                <button
                  type="button"
                  onClick={() => moveDownQuestion(index)}
                  disabled={index === questions.length - 1}
                  className="btn btn-outline-secondary"
                >
                  <i className="bi bi-arrow-down"></i>
                </button>
              </div>
              <div style={{ alignItems: 'center', width: '15%' }}>
                <div style={{ marginLeft: '0.3cm' }}>
                  <QuestionnaireTypeQuestion
                    index={index}
                    handleChange={handleSelectQuestionChange}
                    value={question.type || ''}
                  />
                </div>
                <div style={{ marginLeft: '0.3cm' }}>
                  <button type="button" className="btn btn-primary form-control w-100"
                    index={index}
                    onClick={() => handleQuestionConfiguration(index)}
                  >
                    <i className="bi bi-wrench-adjustable"></i>&nbsp;
                    {t('konfiguracja')}
                  </button>
                </div>
                <div style={{ marginLeft: '0.3cm' }}>
                  {describeQuestionConfig(index)}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="sticky-bottom d-flex justify-content-center">
          <div className="FixedBottomButtons">
            <button type="button" onClick={addQuestion} className="btn btn-primary mr-3 ml-3">
              <i className="bi bi-file-earmark-plus"></i>&nbsp;
              {t("dodaj moduł")}
            </button>
            <button type="button" onClick={handleSaveSchema} className="btn btn-primary mr-3 ml-3">
              <i className="bi bi-floppy ml-3 mr-3"></i>&nbsp;
              {t("zapisz konfigurację")}
            </button>
            <button type="button" onClick={handleCleanSchema} className="btn btn-primary mr-3 ml-3">
              <i className="bi bi-trash ml-3 mr-3"></i>&nbsp;
              {t("wyczyść ankietę")}
            </button>
            <button type="button" onClick={handlePreview} className="btn btn-primary ml-3">
              <i className="bi bi-graph-up-arrow"></i>&nbsp;
              {t("podgląd ankiety")}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default DynamicForm;