import React, { useState, useEffect } from 'react';
import ClientListColumns from './QuestionnaireTemplateColumns';
import DataTableBasejQuery from '../../datatable/table/base/DataTableBasejQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function QuestionnaireTemplateList({ dataSource, localName }) {

  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await fetch(dataSource);
        const json = await response.json();
        setData(json);
        setColumns(ClientListColumns);
      } catch (error) {
        console.error('Data download error:', error);
      }
    };

    fetchData();

  }, [dataSource, location.pathname, localName]);

  useEffect(() => {

    const handleClick = event => {
      const element = event.target.closest('.jqueryDataTable-link');
      if (element) {
        event.preventDefault();
        const path = element.getAttribute('data-href');
        navigate(path);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [navigate]);

  const handleNewTemplate = () => {
    navigate('/konsola/ankiety/0');
  }

  const aLengthMenu = [
    [10, 20, -1],
    [t('mała'), t('większa'), t('wszystko')]
  ]

  const columnDefs = [
    {
      visible: false,
      targets: [0]
    },
  ]

  return (
    <>
      <div className="d-flex justify-content-center mb-4">
        <button className="btn btn-outline-primary form-control" onClick={() => handleNewTemplate()}>{t("dodaj nowy szablon")}</button>
      </div>
      {data.length && columns.length && (
        <DataTableBasejQuery
          columns={columns}
          data={data}
          showFooter={false}
          aLengthMenu={aLengthMenu}
          columnDefs={columnDefs}
          localName={localName}
        />
      )
      }
    </>
  );
}

export default QuestionnaireTemplateList;