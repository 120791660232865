import React, { useState, useEffect, useContext } from 'react';
import { QuestionnaireRenderQuestionOnly, generateFormikConfiguration } from '../QuestionnaireRender';
import DictionaryContext from '../../context/DictionaryContext';

const QuestionnaireRenderQuestionCrossprod = ({ index, question, formik, counter, addSchemaCfg }) => {

    const [questions, setQuestions] = useState([]);
    const [products, setProducts] = useState([]);
    const [questionCfg, setQuestionsCfg] = useState([]);
    const { describeDictionaryValue } = useContext(DictionaryContext);

    useEffect(() => {

        fetch(`/json/ankieta_${question.cfg.idSchemaTemplate}.json`)
            .then(response => response.json())
            .then(data => {
                setQuestions(data.questionsCfg);
            })
            .catch(error => console.error('Błąd podczas odbierania danych:', error));


        fetch(`/json/listaProduktow_${question.cfg.idProductList}.json`)
            .then(response => response.json())
            .then(data => {
                setProducts(data.idProduct);
            })
            .catch(error => console.error('Błąd podczas odbierania danych:', error));
    }, []);


    useEffect(() => {
        if (questions.length > 0 && products.length > 0) {
            const tmpConfig = [];
            for (let i = 0; i < products.length; i++) {
                for (let j = 0; j < questions.length; j++) {
                    const newQuestion = { ...questions[j] };
                    newQuestion.question = newQuestion.question.replace('%PRODUKT%', describeDictionaryValue('Produkt', products[i]));
                    tmpConfig.push(newQuestion)
                }
            }
            setQuestionsCfg(tmpConfig);
            const { initialValues, validationSchema } = generateFormikConfiguration({ questions: tmpConfig, prefix: '_subform_' + counter });
            addSchemaCfg(initialValues, validationSchema);

        }
    }, [questions, products]);

    if (!question.show) return null;

    return (
            <ul>
                <hr />
                {question.question}
                <ol>
                <QuestionnaireRenderQuestionOnly
                    questions={questionCfg}
                    formik={formik}
                    counter={counter * 10000}
                    prefix={'_subform_' + counter}
                />
                </ol>
                <hr />
            </ul>
    );
};

export default QuestionnaireRenderQuestionCrossprod;
