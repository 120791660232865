import { createContext, useContext, useState, useEffect } from 'react';
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import appConfiguration from '../cfg/AppCfg';
import { AuthInterceptor } from "../../auth/AuthInterceptor";

const UserContext = createContext(undefined);

export const UserProvider = ({ children }) => {
  const { userPool } = appConfiguration;
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  let unregisterAuthInterceptor;

  const handleReturnUserOrg = () => {
    setUser(user.orgCfg);
  }

  const handleChangeUserTmp = (userTmp) => {
    const userData = { ...userTmp };
    if (!user.orgUser && user.orgUser !== '') {
      userData.orgUser = user.username;
      userData.orgCfg = user;
    } else {
      userData.orgUser = user.orgUser;
      userData.orgCfg = user.orgCfg;
    }
    setUser(userData);
  }

  const handleLogin = (username, password) => {
    return new Promise((resolve, reject) => {
      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });
      const cognitoUser = new CognitoUser(({
        Username: username,
        Pool: userPool
      }));
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (userSession) => {
          unregisterAuthInterceptor = AuthInterceptor(userSession.getAccessToken().getJwtToken());
          setUser(getMockUserData(username));
          resolve(userSession);
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  };

  const handlePasswordChange = (newPassword) => {
    return Promise.resolve();
    // TODO use Cognito's reset pass approach - send an e-mail, then redirect to pass reset page
  }

  const handleLogout = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      if (unregisterAuthInterceptor) {
        unregisterAuthInterceptor();
      }
      cognitoUser.signOut();
      setUser(null);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const user = await getCurrentUser();
        setUser(getMockUserData(user.email));
        if (!unregisterAuthInterceptor) {
          unregisterAuthInterceptor = AuthInterceptor(user.accessToken);
        }
      } catch (err) {
        if (unregisterAuthInterceptor) {
          unregisterAuthInterceptor();
        }
      }
    }
    fetchUserDetails();
    setLoading(false);
  }, []);

  const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser()
      if (!cognitoUser) {
        reject(new Error("No user found"))
        return
      }
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err)
          return
        }
        cognitoUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err)
            return
          }
          const userData = attributes.reduce((acc, attribute) => {
            acc[attribute.Name] = attribute.Value
            return acc
          }, {})
          resolve({ ...userData, username: cognitoUser.username, accessToken: session.getAccessToken().getJwtToken() })
        })
      })
    })
  }

  const getMockUserData = (username) => {
    return {
      username: username,
      modules: ['ankiety', 'user', 'admin', 'dev'],
      layout: '#F2F2F2',
      lastLogin: '2024-01-01',
      admin: true,
    };
  }

  const getUserInfo = () => {
    if (user) {
      alert(`Zalogowany jako: ${user.username}, ${new Date(user.lastLogin).toString()}`);
    }
  };

  return (
    <UserContext.Provider value={{ user, handleReturnUserOrg, onLogin: handleLogin, onLogout: handleLogout, onPasswordChange: handlePasswordChange, handleChangeUserTmp, userInfo: getUserInfo }}>
      {!loading && children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export default UserContext;
