import React from 'react';
import { defaultSpaceBetweenQuestion } from '../QuestionnaireCfg';
import QuestionnaireRenderQuestionShowOrHide from './QuestionnaireRenderQuestionShowOrHide';
import { Form } from 'react-bootstrap';

const QuestionnaireRenderQuestionSelect = ({ index, question, questions, formik, counter, prefix }) => {

  const fieldName = question.idField;

  const handleChangeForm = (event) => {

    const selectedOptions = Array.from(event.target.selectedOptions).map(option => option.value);

    formik.setFieldValue(fieldName, selectedOptions);
    QuestionnaireRenderQuestionShowOrHide({ event, question, questions, formik });
    formik.handleChange(event);

  }

  if (!question.show) return null;

  return (
    <div style={{ marginTop: defaultSpaceBetweenQuestion }}>
      <label htmlFor={fieldName + 'i'} className="mt-1 questionHead">{(!question.ifReqField || question.ifReqField.lenght === 0) && <span>{counter}. </span>}{question.question}</label>
      <div className='mt-2 questionOption'>
        <Form.Control
          as="select"
          multiple={question.cfg.maxChecked > 1}
          size={question.cfg.sizeList}
          className="form-control "
          name={fieldName}
          onChange={handleChangeForm}
          onBlur={formik.handleBlur}
          value={formik.values[fieldName]}
          isInvalid={formik.touched[fieldName] && formik.errors[fieldName]}
        >
          {question.cfg.variantCfg.map((variant) => {
            return (
              <option key={variant.variant} value={variant.variant} className='questionOption'>{variant.variant}</option>
            );
          })}
        </Form.Control>
        {formik.touched[fieldName] && formik.errors[fieldName] ? (
          <div className="error-message">{formik.errors[fieldName]}</div>
        ) : null}
      </div>
    </div>
  );
};

export default QuestionnaireRenderQuestionSelect;
