import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SelectWrapperJson from '../../common/SelectWrapperJson';
import DictionaryContext from '../../context/DictionaryContext';

const QuestionnaireTypeQuestionCrossProducts = ({ index, questions, setQuestions }) => {

  const { t } = useTranslation();
  const { dictionaryProductsList, dictionaryTemplates } = useContext(DictionaryContext);

  const handleCfgChange = (event, field) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index]['cfg'][field] = event.target.value;
    setQuestions(updatedQuestions);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: '120px' }}>{t('lista produktów')}</div>
        <select
          onChange={(event) => handleCfgChange(event, 'idProductList')}
          className="form-control"
        >
          <SelectWrapperJson
            dataSet={dictionaryProductsList}
            firstOption='wybierz'
            fieldOptionText='listName'
            fieldOptionValue='idProductsList'
            defaultSelected={questions[index]?.cfg?.idProductList}
          />
        </select>
      </div>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: '120px' }}>{t('szablon ankiety')}</div>
        <select
          onChange={(event) => handleCfgChange(event, 'idSchemaTemplate')}
          className="form-control"
        >
          <SelectWrapperJson
            dataSet={dictionaryTemplates}
            firstOption='wybierz'
            fieldOptionText='listName'
            fieldOptionValue='idQuestionnaireTemplateColumns'
            defaultSelected={questions[index]?.cfg?.idSchemaTemplate}
          />
        </select>
      </div>
    </div>
  );
};

export default QuestionnaireTypeQuestionCrossProducts;
