const columns = [
  {
    "data": "idQuestionnaireTemplateColumns",
    "class": "noVis"
  },
  {
    "title": "Nazwa szablonu",
    "data": "listName",
    "class": "filterbyinput",
    "render": function (data, type, full, meta) {
      return `<a class="jqueryDataTable-link" data-href="/konsola/ankiety/${full.idQuestionnaireTemplateColumns}">${data}</a>`;
    }
  }
];

export default columns;