import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionnaireTypeQuestionDependentQuestions from './QuestionnaireTypeQuestionDependentQuestions';
import { defaultInputMaxLength } from '../QuestionnaireCfg';

const QuestionnaireTypeQuestionInput = ({ index, questions, setQuestions }) => {

  const [minLength, setMinLenght] = useState(questions[index]?.cfg?.minLength || 0);
  const [maxLength, setMaxLenght] = useState(questions[index]?.cfg?.maxLength || 0);

  const { t } = useTranslation();

  const handleCfgChange = (event, field) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index]['cfg'][field] = parseInt(event.target.value, 10);
    setQuestions(updatedQuestions);
    if (field === 'minLength') setMinLenght(parseInt(event.target.value, 10));
    if (field === 'maxLength') setMaxLenght(parseInt(event.target.value, 10));
  }

  useEffect(() => {
    if (parseInt(minLength, 10) > parseInt(maxLength, 10)) {
      setMaxLenght(minLength);
      const updatedQuestions = [...questions];
      updatedQuestions[index]['cfg'].maxLength = minLength;
      setQuestions(updatedQuestions);
    }
  }, [minLength, maxLength]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: '150px' }}>{t('walidacja')}</div>
        <select
          onChange={(event) => handleCfgChange(event, 'validation')}
          value={questions[index]?.cfg?.validation}
          className="form-control"
        >
          <option value="empty">{t('brak')}</option>
          <option value="email">{t('adres email')}</option>
          <option value="phone">{t('numer telefonu')}</option>
          <option value="date">{t('data')}</option>
        </select>
      </div>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: '150px' }}>{t('minimalna długość')}</div>
        <input
          type="number"
          min={0}
          max={defaultInputMaxLength}
          value={minLength}
          onChange={(event) => handleCfgChange(event, 'minLength')}
          className="form-control"
        />
      </div>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: '150px' }}>{t("maksymalna długość")}</div>
        <input
          type="number"
          min={0}
          max={defaultInputMaxLength}
          value={maxLength}
          onChange={(event) => handleCfgChange(event, 'maxLength')}
          className="form-control"
        />
      </div>
      <QuestionnaireTypeQuestionDependentQuestions index={index} questions={questions} setQuestions={setQuestions} />
    </div>
  );
};

export default QuestionnaireTypeQuestionInput;