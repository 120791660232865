import React, { useContext } from 'react';
import SelectWrapperJson from '../../common/SelectWrapperJson';
import DictionaryContext from '../../context/DictionaryContext';
import { useTranslation } from 'react-i18next';

const QuestionnaireTypeQuestionBlockTemplate = ({ index, questions, setQuestions }) => {

  const { t } = useTranslation();
  const { dictionaryTemplates } = useContext(DictionaryContext);

  const handleCfgChange = (event, field) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index]['cfg'][field] = parseInt(event.target.value, 10)
    setQuestions(updatedQuestions);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: '120px' }}>{t('szablon ankiety')}</div>
        <select
          onChange={(event) => handleCfgChange(event, 'idSchemaTemplate')}
          className="form-control"
        >
          <SelectWrapperJson
            dataSet={dictionaryTemplates}
            firstOption='wybierz'
            fieldOptionText='listName'
            fieldOptionValue='idQuestionnaireTemplateColumns'
            defaultSelected={questions[index]?.cfg?.idSchemaTemplate}
          />
        </select>
      </div>
    </div>
  );
};

export default QuestionnaireTypeQuestionBlockTemplate;
