import { CognitoUserPool } from "amazon-cognito-identity-js";

const appEnv = 'development';

let loginLinkPost,
    resetLinkPost,
    documentTitle,
    defaultPathAfterLogin,
    defaultCacheDictionary,
    defaultPassword,
    cognitoConfig,
    apiUrl;

switch (appEnv) {
    case 'development':
        cognitoConfig = {
            UserPoolId: 'eu-central-1_9TVOmNrF5',
            ClientId: '3sm4f8rbpu4837qka60nfc7suu'
        };
        apiUrl = 'https://70f4v2gd02.execute-api.eu-central-1.amazonaws.com';
        loginLinkPost = 'https://php.szuscicki.it/post_return.php';
        resetLinkPost = 'https://php.szuscicki.it/post_return.php';
        documentTitle = 'System CRS (dev)';
        defaultPathAfterLogin = 'konsola/ankiety/17';
       // defaultPathAfterLogin = 'konsola/produkty/lista';
        defaultCacheDictionary = 120;
        defaultPassword = 'Alamakota1*@';
        break;
    case 'test':
        cognitoConfig = {
            UserPoolId: 'eu-central-1_9TVOmNrF5',
            ClientId: '3sm4f8rbpu4837qka60nfc7suu'
        };
        apiUrl = 'https://70f4v2gd02.execute-api.eu-central-1.amazonaws.com';
        loginLinkPost = 'https://php.szuscicki.it/post_return.php';
        resetLinkPost = 'https://php.szuscicki.it/post_return.php';
        documentTitle = 'System CRS - test';
        defaultPathAfterLogin = 'konsola/jQueryDataTable';
        defaultCacheDictionary = 180;
        defaultPassword = '';
        break;
    case 'production':
        break;
    default:
        console.log('Nieznane środowisko');
}
const userPool = new CognitoUserPool(cognitoConfig);

const appConfiguration = { appEnv, userPool, apiUrl, defaultCacheDictionary, loginLinkPost, resetLinkPost, documentTitle, defaultPathAfterLogin, defaultPassword };

export default appConfiguration;