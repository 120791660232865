import React from 'react';
import { defaultSpaceBetweenQuestion, defaultSpaceLeftDependQuestion } from '../QuestionnaireCfg';
import QuestionnaireRenderQuestionShowOrHide from './QuestionnaireRenderQuestionShowOrHide';
import { Form } from 'react-bootstrap';

const QuestionnaireRenderQuestionCheckbox = ({ index, question, questions, formik, counter, prefix }) => {

    const fieldName = question.idField;

    const handleChangeForm = (variant) => (event) => {

        if (event.target.checked) {
            formik.setFieldValue(fieldName, [...formik.values[fieldName], variant.variant]);
        } else {
            formik.setFieldValue(fieldName, formik.values[fieldName].filter(value => value !== variant.variant));
        }

        QuestionnaireRenderQuestionShowOrHide({
            event,
            question,
            questions,
            formik
        });

        formik.handleChange(event);

    }

    const inputCheckbox = (variant, idx) => {

        let divStyle = null;
        if (question.cfg.visualization === 'horizontal') {
            divStyle = { display: 'inline-block', marginRight: '0.5cm' };
        }

        return (
            <div key={idx}
                className={question.cfg.visualization === 'vertical' ? "mb-2 mt-2" : ""}
                style={divStyle}
            >
                <Form.Check
                    type="checkbox"
                    id={`question-${index}-${idx}`}
                    label={variant.variant}
                    name={fieldName}
                    value={variant.variant}
                    onChange={handleChangeForm(variant)}
                    checked={formik.values[fieldName].includes(variant.variant)}
                    className="me-2 or mr-2"
                    isInvalid={formik.touched[fieldName] && formik.errors[fieldName]}
                />
            </div>
        );
    }

    if (!question.show) return null;

    return (
        <div
            className={counter % 2 === 0 ? 'secondaryQuestion' : ''}
            style={{ paddingLeft: defaultSpaceLeftDependQuestion, paddingTop: defaultSpaceBetweenQuestion, paddingBottom: defaultSpaceBetweenQuestion }}
        >
            <div style={{
                paddingLeft: question.ifReqField && question.ifReqField.length > 0 ? defaultSpaceLeftDependQuestion : '0',
            }}>
                <label htmlFor={fieldName + 'i'} className="mt-2 questionHead">{(!question.ifReqField || question.ifReqField.lenght === 0) && <span>{counter}. </span>}{question.question}</label>
                <div className='mt-1 questionOption'>
                    {question.cfg.variantCfg.map((variant, idx) => inputCheckbox(variant, idx))}
                    {formik.touched[fieldName] && formik.errors[fieldName] ? (
                        <div className="error-message">{formik.errors[fieldName]}</div>
                    ) : null}
                </div>
            </div>
        </div>
    );

};

export default QuestionnaireRenderQuestionCheckbox;