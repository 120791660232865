import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useUser } from './context/UserContext';
import { isMobile } from './common/CommonFunction';

// tmp.start
import DataTableBaseImportProduktyKolorowanka from './datatable/table/tmp/DataTableBaseImportProduktyKolorowanka';
import DataTableAnkiety from './datatable/table/DataTableAnkiety';
import DataTableTest from './datatable/table/DataTableTest';
import BrakLinku from './BrakLinku';
// tmp.end

import Info from './Info';
import HookUserContext from '../testowe/HookUserContext';
import QuestionnaireEditor from './questionnaire/QuestionnaireEditor';

import PasswordChange from './forms/PasswordChange';


import ClientForm from './client/ClientForm';
import ClientList from './client/datatable/ClientList';
import UserChange from './forms/UsersList';
import AccordionMain from './layout/AccordionMain';
import MobileMenu from './layout/MobileMenu';
import CameraComponent from '../testowe/MediaDevice';
import CurrentPosition from '../testowe/CurrentPosition';
import ProductsList from './products/datatable/ProductsList';
import ProductForm from './products/ProductForm';
import ProductsGroup from './products/ProductsGroup';
import ProductsGroupList from './products/datatable/ProductsGroupList';
// temporary, until the problem with updating the object is solved
import QuestionnaireTemplateList from './questionnaire/datatable/QuestionnaireTemplateList';
import QuestionnaireTemplateList2 from './questionnaire/datatable/QuestionnaireTemplateList2';
//
const Menu = () => {

  const { user } = useUser();

  if (!user) {
    return <Navigate to="/" />;
  }

  const commonRoutes = (
    <Routes>
      <Route path='info' element={<Info />} />
      <Route path='ankiety/:id' element={<QuestionnaireEditor />} />
      <Route path='passwordchange' element={<PasswordChange />} />
      <Route path='hook_userContext' element={<HookUserContext />} />
      <Route path='datatable/produkty/color/:id' element={<DataTableBaseImportProduktyKolorowanka />} />
      <Route path='katalogankiet/*' element={<DataTableAnkiety />} />
      <Route path='klienci/edycja/:id' element={<ClientForm />} />
      <Route path='klienci/lista' element={<ClientList />} />
      <Route path='produkty/lista' element={<ProductsList />} />
      <Route path='produkty/edycja/:id' element={<ProductForm />} />
      <Route path='userChange' element={<UserChange />} />
      <Route path='Camera' element={<CameraComponent />} />
      <Route path='CurrentPosition' element={<CurrentPosition/>} />
      <Route path='produkty/listaGrupa/:id' element={<ProductsGroup />} />
      <Route path='produkty/listaGrup/' element={<ProductsGroupList/>} />
      <Route path='ankietySzablony' element={<QuestionnaireTemplateList2 localName="Szablony" dataSource="/json/questionnaireTemplateList.json"/>}/>
      <Route path='ankietyAnkiety' element={<QuestionnaireTemplateList localName="Ankiety" dataSource="/json/questionnaireQuestionnaireList.json"/>}/>
      <Route path='testdt'element={<DataTableTest/>}/>
      <Route path='*' element={<BrakLinku />} />
    </Routes>
  );

  return (
    <>
      {isMobile() ? (
        <div style={{ minHeight: '100vh', maxWidth: '100%' }}>
          <MobileMenu />
          <div style={{ flexGrow: 1, padding: '20px' }}>{commonRoutes}</div>
        </div>
      ) : (
        <div className="d-flex">
          <AccordionMain />
          <div style={{ flexGrow: 1, padding: '20px'}}>{commonRoutes}</div>
        </div>
      )}
    </>
  );

};

export default Menu;