import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionnaireTypeQuestionDependentQuestions from './QuestionnaireTypeQuestionDependentQuestions';

const QuestionnaireTypeQuestionInputPictures = ({ index, questions, setQuestions }) => {

  const [minQuantity, setMinQuantity] = useState(questions[index]?.cfg?.minQuantity || 0);
  const [maxQuantity, setMaxQuantity] = useState(questions[index]?.cfg?.maxQuantity || 0);
  const { t } = useTranslation();

  const handleCfgChange = (event, field) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index]['cfg'][field] = event.target.value;
    setQuestions(updatedQuestions);
    if (field === 'minQuantity') setMinQuantity(parseInt(event.target.value, 10));
    if (field === 'maxQuantity') setMaxQuantity(parseInt(event.target.value, 10));
  }

  useEffect(() => {
    if (parseInt(minQuantity, 10) > parseInt(maxQuantity, 10)) {
      setMaxQuantity(minQuantity);
      const updatedQuestions = [...questions];
      updatedQuestions[index]['cfg'].minQuantity = minQuantity;
      setQuestions(updatedQuestions);
    }
  }, [minQuantity, maxQuantity]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: '200px' }}>{t('min ilość wyboru')}</div>
        <input
          type="number"
          min={0}
          //                    max={defaultSelectMaxChecked}
          value={minQuantity}
          onChange={(event) => handleCfgChange(event, 'minQuantity')}
          className="form-control"
        />
      </div>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: '200px' }}>{t('max ilość wyboru')}</div>
        <input
          type="number"
          min={0}
          //                    max={defaultSelectMaxChecked}
          value={maxQuantity}
          onChange={(event) => handleCfgChange(event, 'maxQuantity')}
          className="form-control"
        />
      </div>
      <QuestionnaireTypeQuestionDependentQuestions index={index} questions={questions} setQuestions={setQuestions} />
    </div>
  );
};

export default QuestionnaireTypeQuestionInputPictures;